import React, { useState, useEffect } from 'react';
import { Download, Server, Users, Key, AlertTriangle, CheckCircle, Info } from 'lucide-react';

const translations = {
      en: {
            title: "Thank you for your purchase, {username} 🎉",
            subtitle: "This guide will help you protect and effectively distribute your Garry's Mod map.",
            explanation: "To protect your map, we have created two versions of it. Here's how to use them:",
            serverVersion: "Server Version",
            serverInstructions: "File: {serverFile}",
            serverDetails: [
                  "Place this file in the 'maps' folder of your Garry's Mod server.",
                  "This version contains the necessary data for the server to function.",
            ],
            serverWarning: "Warning: Never share this version. It contains sensitive data that could compromise your map's security.",
            clientVersion: "Client Version",
            clientInstructions: "File: {clientFile}",
            clientDetails: [
                  "Distribute this file to players via Steam Workshop or your preferred platform.",
                  "Players must download this version to join your server.",
            ],
            clientSecurity: "Secure: This version is specially designed to be safely shared with your players.",
            licenseKey: "License Key",
            licenseInstructions: "Your unique key:",
            licenseWarning: "Important: Never share this key. It is unique to your purchase and should not be disclosed.",
            downloadButton: "DOWNLOAD MY PROTECTED MAP",
            footer: "© 2024 Mapping Store. All rights reserved.",
      },
      fr: {
            title: "Merci pour votre achat, {username} 🎉",
            subtitle: "Ce guide vous aidera à protéger et distribuer efficacement votre map de Garry's Mod.",
            explanation: "Pour protéger votre map, nous avons créé deux versions de celle-ci. Voici comment les utiliser :",
            serverVersion: "Version Serveur",
            serverInstructions: "Fichier : {serverFile}",
            serverDetails: [
                  "Placez ce fichier dans le dossier 'maps' de votre serveur Garry's Mod.",
                  "Cette version contient les données nécessaires au fonctionnement du serveur.",
            ],
            serverWarning: "Attention : Ne partagez jamais cette version. Elle contient des données sensibles qui pourraient compromettre la sécurité de votre map.",
            clientVersion: "Version Client",
            clientInstructions: "Fichier : {clientFile}",
            clientDetails: [
                  "Distribuez ce fichier aux joueurs via le Workshop Steam ou votre plateforme préférée.",
                  "Les joueurs doivent télécharger cette version pour rejoindre votre serveur.",
            ],
            clientSecurity: "Sécurisé : Cette version est spécialement conçue pour être partagée en toute sécurité avec vos joueurs.",
            licenseKey: "Clé de Licence",
            licenseInstructions: "Votre clé unique :",
            licenseWarning: "Important : Ne partagez jamais cette clé. Elle est unique à votre achat et ne doit pas être divulguée.",
            downloadButton: "TÉLÉCHARGER MA MAP PROTÉGÉE",
            footer: "© 2024 Mapping Store. Tous droits réservés.",
      },
};

const replaceParams = (text, params) => {
      if (typeof text !== 'string') return '';
      return text.replace(/{(\w+)}/g, (match, key) => {
            return params[key] !== undefined ? params[key] : match;
      });
};

const InfoCard = ({ title, icon: Icon, children, color = 'gray' }) => {
      // Define a mapping for colors to Tailwind classes
      const colorStyles = {
            blue: {
                  bg: 'bg-blue-900/20',
                  border: 'border-blue-500/50',
                  text: 'text-blue-200',
                  icon: 'text-blue-400',
            },
            green: {
                  bg: 'bg-green-900/20',
                  border: 'border-green-500/50',
                  text: 'text-green-200',
                  icon: 'text-green-400',
            },
            red: {
                  bg: 'bg-red-900/20',
                  border: 'border-red-500/50',
                  text: 'text-red-200',
                  icon: 'text-red-400',
            },
            yellow: {
                  bg: 'bg-yellow-900/20',
                  border: 'border-yellow-500/50',
                  text: 'text-yellow-200',
                  icon: 'text-yellow-400',
            },
      };

      // Fallback to gray if the color is not defined in the colorStyles
      const styles = colorStyles[color] || colorStyles.gray;

      return (
            <div className={`${styles.bg} backdrop-blur-sm rounded-lg shadow-lg p-6 mb-6 ${styles.text} border ${styles.border}`}>
                  <h3 className="text-xl font-semibold flex items-center mb-4">
                        <Icon className={`mr-2 h-5 w-5 ${styles.icon}`} /> {title}
                  </h3>
                  {children}
            </div>
      );
};


const WarningBox = ({ type, icon: Icon, children }) => {
      const styles = {
            destructive: {
                  bg: 'bg-red-700/30',
                  text: 'text-red-300',
            },
            secure: {
                  bg: 'bg-green-700/30',
                  text: 'text-green-300',
            },
            warning: {
                  bg: 'bg-yellow-700/30',
                  text: 'text-yellow-300',
            },
      };

      const { bg, text } = styles[type] || styles.warning;

      return (
            <div className={`flex items-start p-4 mb-4 rounded-md ${bg} backdrop-blur-sm ${text}`}>
                  <Icon className="h-5 w-5 mr-2 mt-0.5 flex-shrink-0" />
                  <p className="text-sm">{children}</p>
            </div>
      );
};

const ProtectionGuide = () => {
      const [params, setParams] = useState({
            username: 'User',
            serverFile: 'server_file.bsp',
            clientFile: 'client_file.bsp',
            licenseKey: 'XXXX-XXXX-XXXX-XXXX',
            downloadUrl: '#',
            lang: 'en'
      });

      useEffect(() => {
            const searchParams = new URLSearchParams(window.location.search);
            setParams(prevParams => ({
                  ...prevParams,
                  username: searchParams.get('username') || prevParams.username,
                  serverFile: searchParams.get('serverFile') || prevParams.serverFile,
                  clientFile: searchParams.get('clientFile') || prevParams.clientFile,
                  licenseKey: searchParams.get('licenseKey') || prevParams.licenseKey,
                  downloadUrl: searchParams.get('downloadUrl') || prevParams.downloadUrl,
                  lang: searchParams.get('lang') || prevParams.lang
            }));
      }, []);

      const t = translations[params.lang] || translations.en;

      return (
            <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white font-sans p-4 md:p-8">
                  <main className="container mx-auto max-w-4xl">
                        <h1 className="text-4xl md:text-5xl font-bold mb-4 text-center">
                              {replaceParams(t.title, params)}
                        </h1>
                        <p className="text-xl text-center mb-8 text-gray-300">
                              {t.subtitle}
                        </p>

                        <div className="flex items-center justify-center mb-8 bg-blue-900/20 backdrop-blur-sm p-4 rounded-lg border border-blue-500/50">
                              <Info className="h-6 w-6 mr-3 text-blue-400" />
                              <p className="text-lg text-center text-blue-200">
                                    {t.explanation}
                              </p>
                        </div>

                        <div className="grid md:grid-cols-2 gap-6 mb-8">
                              <InfoCard title={t.serverVersion} icon={Server} color="red">
                                    <p className="mb-4">{replaceParams(t.serverInstructions, params)}</p>
                                    <ul className="list-disc list-inside mb-4">
                                          {t.serverDetails.map((detail, index) => (
                                                <li key={index}>{detail}</li>
                                          ))}
                                    </ul>
                                    <WarningBox type="destructive" icon={AlertTriangle}>
                                          {t.serverWarning}
                                    </WarningBox>
                              </InfoCard>

                              <InfoCard title={t.clientVersion} icon={Users} color="green">
                                    <p className="mb-4">{replaceParams(t.clientInstructions, params)}</p>
                                    <ul className="list-disc list-inside mb-4">
                                          {t.clientDetails.map((detail, index) => (
                                                <li key={index}>{detail}</li>
                                          ))}
                                    </ul>
                                    <WarningBox type="secure" icon={CheckCircle}>
                                          {t.clientSecurity}
                                    </WarningBox>
                              </InfoCard>
                        </div>

                        <div className="mb-8 flex justify-center">
                              <a
                                    href={`https://drive.google.com/file/d/${params.downloadUrl}/view?usp=sharing`}
                                    target="_blank"
                                    rel="noopener noreferrer" className="relative bg-gradient-to-r from-green-400 to-green-600 hover:from-green-600 hover:to-green-700 text-white font-bold py-3 px-8 rounded-full inline-flex items-center text-lg transition-transform duration-300 ease-in-out transform hover:scale-105 shadow-lg hover:shadow-xl"
                                    style={{ boxShadow: '0 4px 14px 0 rgba(0, 255, 127, 0.4)' }}
                              >
                                    <Download className="mr-3 h-6 w-6" />
                                    <span className="tracking-wide">{t.downloadButton}</span>
                              </a>
                        </div>


                        <InfoCard title={t.licenseKey} icon={Key} color="yellow">
                              <p className="mb-4">{t.licenseInstructions}</p>
                              <p className="bg-gray-700/20 backdrop-blur-sm p-4 rounded-lg text-center font-mono text-lg mb-4 border border-yellow-500/50 text-yellow-300">
                                    {params.licenseKey}
                              </p>
                              <WarningBox type="warning" icon={AlertTriangle}>
                                    {t.licenseWarning}
                              </WarningBox>
                        </InfoCard>
                  </main>

                  <footer className="mt-12 text-center text-sm text-gray-400">
                        <p>{t.footer}</p>
                  </footer>
            </div>
      );
};

export default ProtectionGuide;
