import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ProtectionGuide from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <React.StrictMode>
            <ProtectionGuide />
      </React.StrictMode>
);